@font-face {
  font-family: "ABCWhyte"; /*Can be any text*/
  src: url("./fonts/ABCWhyte.ttf") format("truetype");
}
html,
body {
  margin: 0px;
  height: 100%;
  color: "#293056";
  font-family: "ABCWhyte";
  overflow: hidden;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

#root {
  height: 100%;
  width: 100%;
  color: "#293056";
}

p {
  color: "#293056";
}

button {
  color: "#293056";
}

div {
  color: "#293056";
}

.audio-react-recorder {
  width: 100%;
  height: 6em;
}

.audio-react-recorder__canvas {
  width: 100%;
  height: 100%;
}
